.header {
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 0) 95%);
  display: flex;
  justify-content: center;
  left: 0;
  padding-bottom: 20px;
  padding-top: 36px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $z-index-header;

  @include tablet {
    padding-bottom: 19.8px;
    padding-top: 38px;
  }

  @include mobile {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 2%, rgba(0, 0, 0, 0) 95%);
    padding: 16px 0;
  }

  &--sidemenu-on {
    .header__sidemenu-backdrop,
    .header__sidemenu-close-button {
      display: none;

      @include tablet {
        display: block;
      }
    }

    .header__menu {
      @include tablet {
        display: block;
        overflow-y: auto;
      }
    }
  }

  &--white2 {
    .header__menu-link--writer {
      background-color: #f2f2f2;
      border: 0 none;
      color: $color-black;

      @include tablet {
        background: none;
        color: $color-white;
        font-size: 18px;
      }
    }
  }

  &--black {
    background: $color-white;
  
    .header__menu-link--writer {
      background-color: #f2f2f2;
      border: 0 none;
      color: $color-black;

      @include tablet {
        background: none;
        color: $color-white;
        font-size: 18px;
      }
    }
  
    .header__menu-link {
      color: $color-black;

      @include tablet {
        color: $color-white;
      }
    }
  }

  &__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    left: 0;
    max-width: $content-max-width;
    padding: 0 20px;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 10;
  
    @include tablet {
      justify-content: center;
    } 
  }

  &__menu {
    @include tablet {
      background-color: $color-black;
      display: none;
      flex-direction: column;
      height: 100vh;
      left: 0;
      margin: 0;
      padding: 26px 40px 0;
      position: fixed;
      top: 0;
      width: 240px;      
    }

    @include mobile {
      padding: 4px 20px 0;
    }
  }

  &__menu-item--home {
    display: none;

    @include tablet {
      display: block;
    }
  }

  &__banner {
    width: 96px;
  
    @include mobile {
      width: 64px;
    }
  }

  &__banner-link {
    text-decoration: none;
  }

  &__banner-image {
    height: 100%;
    width: 100%;
  }

  &__menu-button {
    display: none;
  
    @include tablet {
      background-color: transparent;
      border: 0 none;
      cursor: pointer;
      display: block;
      height: 36px;
      left: 0;
      margin: -02px 10px;
      padding: 0;
      position: absolute;
      top: -02.5px;
      width: 36px;
    }
  
    @include mobile {
      height: 24px;
      top: 0;
      width: 24px;
    }
  }

  &__menu-button-image {
    height: 100%;
    width: 100%;

    &--black {
      fill: $color-black;
    }
  }

  &__menu-bar {
    align-items: center;
    display: flex;
    list-style-type: none;
  
    @include tablet {
      align-items: flex-start;
      background-color: $color-black;
      flex-direction: column;
      margin: 0;
      padding: 0;
    }
  }

  &__menu-link {
    color: $color-white;
    display: block;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin: 0 16px;
    padding: 13px 0;
    text-decoration: none;

    @include tablet {
      margin: 0;
    }
    
    &--writer {
      border: solid 1px #ffffff;
      border-radius: 22px;
      box-shadow: 0 0 1px 0 #ffffff inset, 0 0 1px 0 #ffffff;
      font-size: 16px;
      text-align: center;
      width: 164px;

      @include tablet {
        border: 0 none;
        box-shadow: none;
        font-size: 18px;
        text-align: left;
      }
    }
  
    &--appstore {
      display: block;
      height: 28px;
      margin-left: 28px;
      width: 27px;
    } 
  
    &--playstore {
      display: block;
      height: 28px;
      margin-left: 24px;
      width: 25px;
    }
  }

  &__store-icon {
    height: 100%;
    width: 100%;
  }

  &__sidemenu-close-button {
    background: none;
    border: 0 none;
    display: none;
    width: 24px;

    @include tablet {
      height: 58px;
      margin-left: -20px;
      padding: 17px;
      width: 58px;
    }
    
    @include mobile {
      height: 40px;
      margin-left: -10px;
      padding: 8px;
      width: 40px;
    }
  }

  &__sidemenu {
    display: none;

    @include tablet {
      display: block;
    }
  }
  
  &__sidemenu-backdrop {
    background-color: #00000033;
    display: none;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vh;
  }

  &__sidemenu-cs {
    display: flex;
    margin-top: 36px;
  }

  &__sidemenu-link {
    color: $color-white;
    flex: 1;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 22px;
    text-align: left;
    text-decoration: none;

    &--light {
      color: $color-gray-7;
      font-size: 16px;
      font-weight: normal;
    }
  }

  &__sidemenu-sns {
    display: flex;
    justify-content: space-between;
    width: 140px;
  }

  &__sidemenu-sns-icon {
    display: block;
    height: 20px;
    width: 20px;
  }

  &__sidemenu-links {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }



}

