.policy {
  &.embed {
    .policy__title--h2 {
      display: none;
    }

    .policy__content {
      padding-top: 40px;
    }
  }

  &__section {
    position: relative;

    ol,
    ul {
      list-style: decimal;
      list-style-position: inside;

      margin-block-end: 1em;
      margin-block-start: 1em;

      padding-inline-end: 0;
      padding-inline-start: 0;
      padding-left: 1em;

      @include mobile {
        padding-left: 0.5em;
      }
    }

    li {
      h3 {
        display: inline;
      }
    }
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: $content-max-width;
    padding: 200px 40px;
    position: relative;

    @include tablet {
      padding: 120px 40px 90px;
    }

    @include mobile {
      padding: 80px 20px 90px;
    }

    &--article {
      max-width: 1024px;
    }
  }

  &__title {
    color: $color-white;
    font-family: Poppins;
    position: relative;

    &--h2 {
      color: $color-black;
      font-size: 64px;
      font-weight: bold;
      line-height: 1.25;
      // padding-bottom: 64px;
      text-align: center;

      @include tablet {
        font-size: 46px;
        padding-bottom: 46px;
      }

      @include mobile {
        font-size: 28px;
        padding-bottom: 28px;
      }
    }

    &--h3 {
      color: $color-black;
      font-size: 46px;
      font-weight: bold;
      padding: 37px 0 18px;

      @include tablet {
        font-size: 28px;
        padding: 28px 0 14px;
      }

      @include mobile {
        font-size: 20px;
        padding: 20px 0 10px;
      }
    }
  }

  &__text {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.5;

    @include tablet {
      font-size: 14px;
      line-height: normal;
    }

    @include mobile {
      font-size: 13px;
    }

    &--list-description {
      padding-top: 8px;

      b {
        font-weight: 600;
      }
    }

    &--list-item {
      padding-bottom: 8px;
      word-break: break-word;

      b {
        font-weight: 600;
      }
    }
  }

  &__link {
    color: $color-radish;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__updated {
    font-family: Poppins;
    font-size: 20px;
    font-style: italic;
    font-weight: 300;
    margin-right: auto;
    padding-bottom: 64px;
  }
}
