.about {
  &__section {
    position: relative;
  }

  &__featured {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 810px;
    justify-content: center;
    position: relative;
    
    @include tablet {
      height: 600px;
    }

    @include mobile {
      height: 460px;
    }
  }

  &__background {
    background-image: url("/assets/about_bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;

    @include tablet {
      background-image: url("/assets/about_bg_small.jpg");
    }
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: $content-max-width;
    padding: 120px 40px 0;
    position: relative;

    @include tablet {
      padding: 60px 40px 0;
    }
    
    @include mobile {
      padding: 40px 20px 0;
    }

    &--article {
      max-width: 1024px;
    }
    
    &--managers {
      overflow: hidden;
      padding-left: 0;
      padding-right: 0;
    } 
  }

  &__title {
    color: $color-white;
    font-family: Poppins;
    position: relative;

    &--h2 {
      font-size: 80px;
      font-weight: bold;
      text-align: center;
      text-shadow: 0 10px 60px #00000099;

      @include tablet {
        font-size: 38px;
      }

      @include mobile {
        line-height: normal;
        margin: 0 auto;
        max-width: 294px;
      }
    }

    &--h3 {
      color: $color-black;
      font-size: 64px;
      font-weight: bold;
      line-height: 1.25;
      padding-bottom: 37px;
      text-align: center;

      @include tablet {
        font-size: 38px;
        padding-top: 20px;
      }
      
      @include mobile {
        font-size: 28px;
      }
    }

    &--h4 {
      color: $color-black;
      font-size: 46px;
      font-weight: bold;

      @include tablet {
        font-size: 36px;
      }
      
      @include mobile {
        font-size: 20px;
      }
    }
  }

  &__text {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
    width: 100%;

    @include tablet {
      font-size: 14px;
      line-height: normal;
    }
    
    @include mobile {
      font-size: 13px;
    }
  }

  &__prides {
    display: flex;
    font-family: Poppins;
    justify-content: space-between;
    margin-top: 80px;
    width: 100%;

    @include tablet {
      margin-top: 50px;
    }
    
    @include mobile {
      flex-wrap: wrap;
      margin-top: 40px;
    }
  }

  &__pride {
    align-items: center;
    border-radius: 12px;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 290px;
    justify-content: center;
    line-height: 1.4;
    margin-right: 40px;
    /* NOTE: Using max-width flex element cause a bug on IE 11 */
    // max-width: 226px;

    @include tablet {
      height: 245px;
      margin-right: 1.5%;
    }
    
    @include mobile {
      flex: none;
      height: 220px;
      margin: 0 0 16px;
      width: 47.5%;
    }

    &--founded {
      background-image: linear-gradient(213deg, #ff00ef 97%, #ff0091), linear-gradient(to bottom, #4c68ec, #4c68ec);
    }

    &--published {
      background-image: linear-gradient(to bottom, #96daff, #5db3ff), linear-gradient(to bottom, #f7f7f7, #f7f7f7);
    }

    &--authors {
      background-image: linear-gradient(to bottom, #db96ff, #e65dff 99%), linear-gradient(to bottom, #f7f7f7, #f7f7f7);
    }

    &--reads {
      background-image: linear-gradient(to bottom, #96b0ff, #5d77ff);
      margin-right: 0;
    }
  }

  &__pride-title {
    color: $color-white;
    font-size: 58px;
    font-weight: bold;
    letter-spacing: -01px;

    @include tablet {
      font-size: 40px;
    }    

    &--published,
    &--authors,
    &--reads {
      font-size: 48px;

      @include tablet {
        font-size: 30px;
      }
    }
  }

  &__pride-description {    
    color: $color-white;
    font-size: 21px;
    font-weight: 600;
    text-align: center;

    @include tablet {
      font-size: 16px;
    }
  }

  &__apply-author {
    padding-top: 50px;

    @include tablet {
      padding-top: 40px;
    }
  }

  &__round-button {
    align-items: center;
    background-color: $color-black;
    border: 0 none;
    border-radius: 34px;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    font-family: Poppins;
    font-size: 21px;
    font-weight: 600;
    height: 68px;
    justify-content: center;
    line-height: 1.2;
    padding: 0 30px;
    text-align: center;
    text-decoration: none;

    @include tablet {
      font-size: 15px;
      height: 44px;
    }
    
    &--light {
      background-color: $color-white;
      color: $color-black;
      font-weight: bold;
    }
  }

  &__managers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-right: -20px;
    width: 100%;

    @include tablet {
      margin-right: -34px;
    }
    
    @include mobile {
      margin-right: 0;
    }

    .manager-item {
      margin-right: 20px;

      @include tablet {
        margin-right: 34px;
      }
  
      @include mobile {
        margin-right: 0;
      }
    }
  }

  &__join {
    background-image: linear-gradient(to bottom, #fafafa, #f2f2f2);
    box-sizing: border-box;
    height: 360px;
    margin: 120px auto 100px;
    padding: 56px 42px 80px;
    width: 100%;

    @include tablet {
      height: 320px;
      margin: 60px auto 0;
      padding: 63px 22px 68px;
    }
    
    @include mobile {
      height: 200px;
      padding: 48px 10px 50px;
    }
  }

  &__join-text {
    color: #222222;
    font-family: Poppins;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 47px;
    margin-top: 12px;
    text-align: center;

    @include tablet {
      font-size: 19px;
      margin-bottom: 42px;
    }
    
    @include mobile {
      font-size: 13px;
      margin-bottom: 17px;
    }
  }
}

.manager-item {
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 320px;
  justify-content: center;
  margin-bottom: 40px;
  padding: 20px;
  position: relative;
  width: 400px;

  @include tablet {
    max-width: 360px;
    width: 48%;
  }
  
  @include mobile {margin-bottom: 20px;
    max-width: 335px;
    width: 100%;
  }

  &__role {
    color: #4a90e2;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    margin-bottom: 8px;
  }

  &__name {
    color: #222222;
    font-family: Poppins, sans-serif;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  &__biography {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__biography-text {
    color: #888888;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    height: 5.2em;
    line-height: 1.3;
    margin-bottom: 6px;
    overflow: hidden;
    text-align: center;
    width: 100%;
  }

  &__more-button {
    background: none;
    border: 0 none;
    color: #999999;
    cursor: pointer;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    font-weight: bold;
    height: 24px;
    width: auto;

    &:hover {
      border-bottom: 2px solid #999999;
    }
  }

  &__portrait {
    border-radius: 50%;
    margin-bottom: 16px;
    width: 100px;
  }

  &__linkedin-link {
    &:hover {
      .manager-item__linkedin-icon--fill {
        fill: $color-radish;
      }
    }
  }

  &__linkedin-icon {
    height: 20px;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;

    @include tablet {
      height: 18px;
      right: 18px;
      top: 18px;
      width: 18px;
    }

    @include mobile {
      height: 17px;
      right: 17px;
      top: 17px;
      width: 17px;
    }

    &--fill {
      fill: $color-linkedin;
    }
  }
}
