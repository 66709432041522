/* stylelint-disable unit-disallowed-list */
$threshold-mobile-and-tablet: 600px;
$threshold-tablet-and-desktop: 900px;
$desktop-maximum: 1920px;
$content-max-width: 1280px;
  
$color-radish: #ff008a;
$color-black: #000000;
$color-white: #ffffff;
$color-gray-4c: #4c4c4c;
$color-gray-6: #666666;
$color-gray-7: #777777;
$color-gray-9: #999999;

$color-linkedin: #1a85bc;

$z-index-header: 100;
$z-index-profile-modal: 101;
