.news {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;

  &__content {
    margin-top: 200px;
    max-width: $content-max-width;
    position: relative;
    width: 100%;

    @include tablet {
      margin-top: 114px;
      width: 100%;
    }

    @include mobile {
      margin-top: 55px;
      width: 100%;
    }
  }

  &__press {
    color: #4a90e2;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 500;
    margin-right: 10px;

    @include tablet {
      font-size: 15px;
    }

    @include mobile {
      font-size: 13px;
    }
  }

  &__publish-date {
    color: #aaaaaa;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;

    @include tablet {
      font-size: 15px;
    }

    @include mobile {
      font-size: 13px;
    }
  }

  &__title {
    color: #000000;
    display: inline-block;
    font-family: Poppins;
    font-size: 42px;
    font-weight: bold;
    line-height: 1.22;
    margin-top: 10px;
    text-decoration: none;

    @include tablet {
      font-size: 32px;
      margin-top: 5px;
    }

    @include mobile {
      font-size: 20px;
      margin-top: 5px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__summary {
    color: #222222;
    font-family: Poppins;
    font-size: 18px;
    line-height: 1.36;
    margin: 20px 0 12px;

    @include tablet {
      font-size: 15px;
    }

    @include mobile {
      font-size: 13px;
    }
  }

  &__more {
    color: #aaaaaa;
    font-family: Poppins;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: normal;
    margin: 12px 17px 0 0;
    text-decoration: none;

    @include tablet {
      font-size: 16px;
    }
  }
}

.news-featured {
  padding: 0 40px 32px;
  position: relative;

  @include tablet {
    padding: 0 41px;
  }

  @include mobile {
    padding: 0 20px;
  }

  &__item {
    display: flex;
    flex-direction: row;
    padding-bottom: 48px;

    @include tablet {
      padding-bottom: 60px;
    }

    @include mobile {
      flex-direction: column;
      padding-bottom: 40px;
    }
  }

  &__thumbnail {
    height: 170px;
    margin-right: 40px;
    margin-top: 40px;
    object-fit: fill;
    object-position: center top;
    position: relative;
    width: 280px;

    @include tablet {
      height: 138px;
      margin-right: 30px;
      margin-top: 30px;
      width: 226px;
    }

    @include mobile {
      height: auto;
      margin: 0 0 20px;
      width: 100%;
    }
  }
}

.news-regular {
  padding: 0 40px 60px;
  position: relative;

  @include tablet {
    padding: 0 41px 60px;
  }

  @include mobile {
    padding: 0 20px 60px;
  }

  &__label {
    color: #000000;
    display: block;
    font-family: Poppins;
    font-size: 48px;
    font-weight: bold;
    line-height: 1.58;
    margin: 0 0 28px;

    @include tablet {
      font-size: 36px;
      margin: 0 0 20px;
    }

    @include mobile {
      font-size: 28px;
      margin: 0 0 18px;
    }
  }

  &__show-less {
    :nth-child(n + 4) {
      display: none;
    }
  }

  &__item {
    padding-bottom: 30px;
  }

  &__title {
    color: #000000;
    display: inline-block;
    font-family: Poppins;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.25;
    margin-top: 10px;
    text-decoration: none;

    @include tablet {
      font-size: 24px;
      margin-top: 10px;
    }

    @include mobile {
      font-size: 16px;
      margin-top: 4px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__more {
    color: #aaaaaa;
    cursor: pointer;
    font-family: Poppins;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: normal;

    @include tablet {
      font-size: 16px;
    }
  }
}

.news-investors {
  padding: 0 40px 43px;

  @include tablet {
    padding: 0 41px 120px;
  }

  @include mobile {
    padding: 0 20px 51px;
  }

  &__grid-container {
    column-gap: 90px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(88px, 1fr));
    row-gap: 51px;

    @include tablet {
      column-gap: 15px;
      grid-template-columns: repeat(4, auto);
      row-gap: 20px;
    }

    @include mobile {
      column-gap: 8px;
      grid-template-columns: repeat(2, auto);
      row-gap: 10px;
    }
  }

  &__grid-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;

    &:hover {
      .news-investors__tooltip {
        display: flex;

        @include tablet {
          display: none;
        }

        @include mobile {
          display: none;
        }
      }
    }
  }

  &__image {
    border-radius: 50px;
    height: 88px;
    object-fit: contain;
    width: 88px;
  }

  &__tooltip {
    align-items: center;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.21);
    box-sizing: border-box;
    display: none;
    flex-direction: column;
    justify-content: center;
    padding: 15px 24px;

    position: absolute;
    top: 98px;
    width: 210px;
    z-index: 1;
  }

  &__info {
    display: none;

    @include tablet {
      display: block;
      height: 104px;
      margin-top: 15px;
    }

    @include mobile {
      display: block;
      height: 104px;
      margin-top: 7px;
    }
  }

  &__name {
    color: #000000;
    font-family: Poppins;
    font-size: 20px;
    font-weight: bold;
    text-align: center;

    @include tablet {
      font-size: 13px;
    }

    @include mobile {
      font-size: 13px;
    }
  }

  &__bio {
    color: #777777;
    font-family: Poppins;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-top: 5px;
    text-align: center;

    @include tablet {
      font-size: 12px;
    }

    @include mobile {
      font-size: 12px;
    }
  }
}

.news-contact {
  align-items: center;
  background-image: linear-gradient(to bottom, #fafafa, #f2f2f2);
  display: flex;
  flex-direction: column;
  height: 360px;
  justify-content: center;
  margin-bottom: 100px;
  width: 100%;

  @include tablet {
    height: 320px;
    margin-bottom: 0;
  }

  @include mobile {
    height: 200px;
  }

  &__title {
    color: #000000;
    font-family: Poppins;
    font-size: 46px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;

    @include tablet {
      font-size: 36px;
    }

    @include mobile {
      font-size: 20px;
    }
  }

  &__desc {
    color: #222222;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    margin: 12px 0 47px;
    text-align: center;

    @include tablet {
      font-size: 19px;
      margin: 15px 0 42px;
    }

    @include mobile {
      font-size: 13px;
      margin: 2px 0 14px;
    }
  }

  &__button {
    align-items: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 40px;
    box-sizing: border-box;

    color: #000000;
    display: flex;
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    height: 68px;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    width: 380px;

    @include tablet {
      font-size: 19px;
      height: 54px;
      width: 300px;
    }

    @include mobile {
      font-size: 13px;
      height: 38px;
      width: 210px;
    }
  }
}
