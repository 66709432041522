.error {
  &__section {
    align-items: center;
    display: flex;
    height: 100%;
    height: 100vh;
    justify-content: center;
    min-height: 624px;
    position: relative;
  }

  &__image {
    display: block;
    height: 278px;
    width: 379px;
 
    @include mobile {
      height: unset;
      width: 80%;
    }
  }
}
