.writer {
  &__section {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: Poppins;
    margin: 0 auto;
    max-width: $content-max-width;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 130px;
    position: relative;

    @include tablet {
      align-items: center;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 50px;
      padding: 60px 0 0;
    }

    @include mobile {
      margin-top: 20px;
      padding-top: 0;
    }
  }

  &__content {
    column-gap: 46px;
    display: grid;
    grid-auto-flow: column;

    @include tablet-only {
      display: flex;
      flex-direction: column;
    }

    @include mobile {
      column-gap: unset;
      display: flex;
      flex-direction: column;
      grid-template-columns: unset;
      grid-template-rows: unset;
      margin: 28px 0 80px;
      row-gap: unset;

      :last-child {
        margin-bottom: 0;
      }
    }
  }

  &__item-title {
    color: #0f0f0f;
    font-family: Poppins;
    position: relative;

    &--h3 {
      color: #0f0f0f;
      font-size: 36px;
      font-weight: bold;
      height: 75px;
      line-height: 45px;
      margin-left: 32px;
      margin-top: -40px;
      text-align: left;
      vertical-align: middle;

      @include tablet-only {
        font-size: 36px;
        height: auto;
        margin-top: 20px;
      }

      @include mobile {
        font-size: 36px;
        height: auto;
        margin-left: 0;
        margin-top: -50px;
      }
    }
  }

  &__item {
    align-content: flex-start;
    display: grid;
    grid-auto-flow: row;
    max-width: 275px;
    position: relative;
    row-gap: 16px;

    @include tablet-only {
      display: flex;
      flex-direction: row;
      max-width: unset;
      row-gap: 0;
      width: auto;
    }

    @include mobile {
      row-gap: 20px;
    }

    &-image {
      margin-left: 20px;
      @include tablet-only {
        align-self: flex-start;
      }
      @include mobile {
        margin-left: 0;
      }
    }

    &-round-button {
      align-items: center;
      background-color: $color-black;
      border-radius: 34px;
      color: #ffffff;
      display: inline-flex;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      height: 48px;
      justify-content: center;
      line-height: 1.2;
      margin-bottom: 15px;
      margin-left: 32px;
      margin-top: 30px;
      text-decoration: none;
      width: 140px;

      @include tablet-only {
        font-size: 15px;
        height: 44px;
      }
      
      @include mobile {
        margin-bottom: 8px;
        margin-left: 0;
      }

      &--light {
        background-color: $color-white;
        color: $color-black;
        font-weight: bold;
      }
    }

    &-content-grid {
      align-items: flex-start;
      display: grid;
      margin: 12px 0 88px;

      @include tablet-only {
        column-gap: unset;
        display: flex;
        flex-direction: column;
        grid-template-columns: unset;
        grid-template-rows: unset;
        margin: 10px 20px 60px 0;
        row-gap: unset;
        width: auto;
      }

      @include mobile {
        column-gap: unset;
        display: flex;
        flex-direction: column;
        grid-template-columns: unset;
        grid-template-rows: unset;
        margin: 10px 0 60px -20px;
        row-gap: unset;
      }

      &-item-description {
        font-size: 16px;
        line-height: 1.5;

        @include tablet-only {
          line-height: normal;
          margin-left: 15px;
        }

        @include mobile {
          font-size: 14px;
          line-height: normal;
        }
      }

      &-item {
        display: flex;
        padding-bottom: 25px;
      }

      &-item-no {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        padding: 0 20px 0 0;
        text-shadow: 0 8px 600px #000000;

        @include tablet-only {
          margin-left: -10px;
        }

        @include mobile {
          font-size: 14px;
        }
      }
    }
  }
}
