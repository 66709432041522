.footer {
  background-color: $color-black;
  display: flex;
  height: 320px;
  justify-content: center;
  width: 100%;

  @include tablet {
    height: 288px;
  }

  @include mobile {
    height: 220px;
  }

  &__content {
    display: flex;
    max-width: calc(#{$content-max-width} - 40px);
    padding: 54px 20px 58px;
    width: 100%;

    @include tablet {
      display: flex;
      padding-bottom: 24px;
      padding-left: 0;
      padding-right: 0;
      padding-top: 35px;
    }

    @include mobile {
      display: flex;
      padding-bottom: 16px;
      padding-top: 24px;
    }
  }

  &__logo {
    height: 28px;
    width: 96px;

    @include mobile {
      height: 20px;
      width: 69px;
    }
  }

  &__main {
    display: flex;
    flex: 1;
    flex-direction: column;

    @include tablet {
      align-items: center;
    }
  }

  &__cs {
    display: flex;
    flex-direction: row;
    margin-top: 28px;

    @include tablet {
      margin-top: 42px;
      text-align: center;
    }

    @include mobile {
      margin-top: 38px;
    }
  }

  &__contact {
    margin-right: 20px;
  }

  &__links {
    display: flex;
    flex-basis: 240px;
    flex-direction: column;
    justify-content: space-between;

    @include tablet {
      display: none;
    }
  }

  &__link {
    color: $color-white;

    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;

    @include tablet {
      font-size: 23.2px;
    }

    @include mobile {
      font-size: 16px;
    }

    &:hover {
      text-decoration: underline;
    }

    &--emphasized {
      font-weight: 600;
    }

    &--light {
      font-weight: 400;
    }

    &--with-margin {
      margin-left: 20px;
    }
  }

  &__share {
    display: flex;
    justify-content: space-between;
    margin-top: 36px;
    width: 156px;

    @include tablet {
      margin-top: 42px;
      width: 320px;
    }

    @include mobile {
      max-width: 230px;
      width: 100%;
    }
  }

  &__sns-icon {
    height: 24px;
    text-decoration: none;
    width: 24px;

    @include tablet {
      height: 29px;
      width: 29px;
    }

    @include mobile {
      height: 20px;
      width: 20px;
    }
  }

  &__inner-links {
    display: flex;
    flex-direction: column;

    @include tablet {
      flex-direction: column-reverse;
    }
  }

  &__radish {
    color: $color-gray-6;
    font-family: Poppins;
    font-size: 18px;
    line-height: 1.33;
    margin-top: 24px;
    width: 240px;

    @include tablet {
      font-size: 17.4px;
      margin-top: 16px;
      text-align: center;
      width: 100%;
    }

    @include mobile {
      font-size: 12px;
    }
  }
}
