.profile-modal {
  align-items: center;
  background-color: #00000066;
  display: none;
  height: 100%;

  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  width: 100vw;
  z-index: $z-index-profile-modal;

  &__backdrop {
    background-color: #00000066;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  &__box {
    background-image: linear-gradient(142deg, #ff00bef5 2%, #ff008af5 99%);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 640px;
    padding: 0 20px 40px;
    position: relative;
    width: 520px;
    z-index: 1;
    
    @include mobile {
      background-image: linear-gradient(151deg, #ff00bef5 2%, #ff008af5 99%);
      height: 100%;
      width: 100%;
    }
  }

  &__bg {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 0;
  }

  &__header {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: space-between;
    width: 100%;
  }

  &__logo {
    width: 64px;
  }

  &__close-button {
    background: none;
    border: 0 none;
  }

  &__scrollable-wrap {
    flex: 1;
    margin: 0 auto;
    max-width: 420px;
    overflow: auto;
    position: relative;
    width: 100%;
    z-index: 1;
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__portrait {
    border-radius: 50%;
    height: 100px;
    object-fit: cover;
    width: 100px;
  }

  &__role {
    color: $color-white;
    font-family: Poppins;
    font-size: 16px;
    margin: 14px 0 8px;
  }

  &__name {
    color: $color-white;
    font-family: Poppins;
    font-size: 22px;
    font-weight: bold;
  }

  &__biography {
    color: $color-white;
    font-family: Poppins;
    font-size: 13px;
    line-height: 20px;
    padding: 18px 0;
    text-align: center;
    width: 100%;
  }
}
