.html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hide {
  /* stylelint-disable declaration-no-important */
  display: none !important;
}

.a11y-hidden {
  clip-path: polygon(0 0, 0 0, 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

.lazy {
  background-image: none !important;
}

.hide-on-tablet {
  @include tablet {
    display: none !important;
  }
}

.hide-on-mobile {
  @include mobile {
    display: none !important;
  }
}

.body--fixed-backdrop-on-tablet {
  @include tablet {
    height: 100%;
    height: 100vh;
    overflow: hidden;
    width: 100%;
    width: 100vw;
  }
}

.body--profile-modal {
  .profile-modal {
    display: flex;
  }
}
