/* stylelint-disable unit-disallowed-list */
@mixin mobile {
  @media (max-width: #{$threshold-mobile-and-tablet - 1px}) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: #{$threshold-mobile-and-tablet}) and (max-width: #{$threshold-tablet-and-desktop - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$threshold-tablet-and-desktop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$threshold-tablet-and-desktop}) {
    @content;
  }
}
