.jobs {
  &__section {
    position: relative;
  }

  &__featured {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 810px;
    justify-content: center;
    position: relative;

    @include tablet {
      height: 600px;
    }

    @include mobile {
      height: 460px;
    }
  }

  &__background {
    background: url("/assets/jobs_bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  &__bannerimg {
    object-fit: cover;
    position: absolute;
    width: 100%;

    @include tablet {
      height: 600px;
      object-position: center;
    }
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: $content-max-width;
    padding: 120px 60px 0;
    position: relative;

    @include tablet {
      padding: 60px 40px 0;
    }

    @include mobile {
      padding: 40px 20px 0;
    }

    &--open-positions {
      padding-bottom: 120px;

      @include tablet {
        padding-bottom: 100px;
      }

      @include mobile {
        padding-bottom: 60px;
      }

      &--show-partial {
        .jobs__open-positions-table-row:nth-child(n + 11) {
          display: none;
        }

        .jobs__open-positions-button {
          display: block;
        }
      }
    }
  }

  &__title {
    color: $color-white;
    font-family: Poppins;
    position: relative;

    &--h2 {
      font-size: 80px;
      font-weight: bold;
      text-align: center;
      text-shadow: 0 10px 60px #00000099;

      @include tablet {
        font-size: 38px;
      }

      @include mobile {
        line-height: normal;
        margin: 0 auto;
        max-width: 294px;
      }
    }

    &--h3 {
      color: $color-black;
      font-size: 64px;
      font-weight: bold;
      text-align: center;

      @include tablet {
        font-size: 38px;
      }

      @include mobile {
        font-size: 28px;
      }
    }

    &--h4 {
      color: #0f0f0f;
      font-size: 32px;
      font-weight: bold;

      @include tablet {
        font-size: 20px;
      }

      @include mobile {
        font-size: 16px;
      }
    }
  }

  &__subtitle {
    box-sizing: border-box;
    color: $color-white;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    margin: 45px auto 0;
    max-width: 942px;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
    text-align: center;
    width: 100%;

    @include tablet {
      font-size: 16px;
      line-height: 2;
      margin-top: 16px;
      max-width: 553px;
      padding-left: 20px;
      padding-right: 20px;
    }

    @include mobile {
      font-size: 16px;
      line-height: normal;
      margin-top: 10px;
      max-width: 294px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__culture-list {
    column-gap: 20px;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 0.9fr 1fr;
    grid-template-rows: repeat(4, auto);
    margin: 64px 0 100px;
    row-gap: 64px;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, minmax(98px, auto));
      margin: 40px 0 60px;
      row-gap: 27px;
    }

    @include mobile {
      column-gap: unset;
      display: flex;
      flex-direction: column;
      grid-template-columns: unset;
      grid-template-rows: unset;
      margin: 28px 0 60px;
      row-gap: unset;

      :last-child {
        margin-bottom: 0;
      }
    }
  }

  &__culture-list-item {
    align-items: flex-start;
    display: flex;
    max-width: 520px;

    @include mobile {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 27px;
      max-width: 300px;
      text-align: center;
    }
  }

  &__culture-list-item-no {
    color: $color-gray-9;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    padding: 0 20px 0 0;
    padding-top: 2px;
    text-shadow: 0 8px 600px #000000;

    @include mobile {
      font-weight: bold;
      padding: 0 0 12px;
    }
  }

  &__culture-list-item-description {
    color: #0f0f0f;
    font-family: Poppins;
    font-size: 15px;
    line-height: 1.47;
    padding: 10px 0 0;

    @include tablet {
      font-size: 13px;
      line-height: normal;
    }

    @include mobile {
      font-size: 12px;
      line-height: normal;
    }
  }

  &__culture-image {
    height: auto;
    max-width: 1024px;
    width: 100%;

    @include tablet {
      width: 100%;
    }

    @include mobile {
      width: 100%;
    }
  }

  &__open-positions-description {
    box-sizing: border-box;
    color: $color-black;
    font-family: Poppins;
    font-size: 22px;
    line-height: normal;
    margin: 46px 0 25px;
    max-width: 940px;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
    width: 100%;

    @include tablet {
      font-size: 15px;
      margin: 17px 0 30px;
      max-width: 692px;
      padding-left: 20px;
      padding-right: 20px;
    }

    @include mobile {
      font-size: 13px;
      margin: 22px 0 13px;
      max-width: 335px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__open-positions-select {
    appearance: none;
    background: url("/assets/view-more.svg") right center no-repeat;
    background-color: $color-white;
    background-size: 45px 20px;
    border: solid 1px #d2d2d2;
    border-radius: 27px;
    color: $color-black;
    flex: 1;
    font-family: Poppins;
    font-size: 19px;
    height: 54px;
    line-height: normal;
    padding: 0 20px;
    width: 378px;

    @include tablet {
      background-size: 30px 15px;
      font-size: 14px;
      height: 43px;
      max-width: 300px;
      padding: 0 15px;
      width: 100%;
    }

    ::-ms-expand {
      display: none;
    }
  }

  &__open-positions-form {
    display: flex;
    justify-content: center;

    @include tablet {
      width: 100%;
    }

    :first-child {
      margin-right: 14px;

      @include mobile {
        margin-right: 10px;
      }
    }
  }

  &__open-positions-table {
    border-collapse: collapse;
    display: table;
    margin: 80px 0 40px;
    width: 100%;

    @include tablet {
      margin: 50px 0 30px;
    }

    @include mobile {
      display: flex;
      flex-direction: column;
      margin: 20px 0 30px;
    }
  }

  &__open-positions-table-row {
    border-bottom: 1px solid #e7e9f5;
    display: table-row;
    height: 90px;
    position: relative;

    @include tablet {
      height: 63px;
    }

    @include mobile {
      display: flex;
      flex-direction: column;
      height: 111px;
      justify-content: center;
    }

    &--header {
      border: 0 none;
      height: 60px;

      @include tablet {
        height: 30px;
      }

      @include mobile {
        display: none;
      }
    }

    :last-child {
      padding-right: 0;
    }
  }

  &__open-positions-table-column {
    display: table-cell;
    padding-right: 30px;
    vertical-align: middle;

    @include tablet {
      padding-right: 15px;
    }

    @include mobile {
      padding-right: 0;
    }

    &--float-right-on-mobile {
      @include mobile {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  &__open-positions-table-heading {
    color: $color-gray-9;
    font-family: Poppins;
    font-size: 19px;
    font-weight: 600;

    @include tablet {
      font-size: 12px;
    }

    @include mobile {
      display: none;
    }
  }

  &__open-positions-job-title {
    -webkit-box-orient: vertical;
    color: $color-black;
    display: -webkit-box;
    font-family: Poppins;
    font-size: 18px;
    font-weight: bold;
    -webkit-line-clamp: 2;
    line-height: 25px;
    max-width: 424px;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: normal;
    width: 100%;
    word-wrap: break-word;

    @include tablet {
      font-size: 14px;
      line-height: 20px;
      max-width: 242px;
    }

    @include mobile {
      font-size: 14px;
      line-height: 20px;
      max-width: 212px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__open-positions-department {
    color: $color-black;
    display: block;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    max-width: 211px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    @include tablet {
      font-size: 13px;
      line-height: 19px;
      max-width: 125px;
    }

    @include mobile {
      color: #888888;
      height: 19px;
    }
  }

  &__open-positions-location {
    color: $color-black;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    text-overflow: ellipsis;

    @include tablet {
      font-size: 13px;
    }

    @include mobile {
      display: none;
    }
  }

  &__open-positions-location-and-employment-type {
    color: $color-black;
    display: none;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    text-overflow: ellipsis;

    @include mobile {
      color: #888888;
      display: block;
      font-size: 13px;
      line-height: 17px;
      margin-bottom: 10px;
    }
  }

  &__open-positions-employment-type {
    color: $color-black;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    text-overflow: ellipsis;

    @include tablet {
      font-size: 13px;
    }

    @include mobile {
      display: none;
    }
  }

  &__open-positions-read-more {
    color: $color-radish;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;

    text-decoration: none;
    text-overflow: ellipsis;

    @include tablet {
      font-size: 13px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__open-positions-table-row-group {
    display: table-row-group;
  }

  &__open-positions-no-jobs {
    align-items: center;
    color: $color-gray-6;
    display: flex;
    font-family: Poppins;
    font-size: 16px;
    height: 250px;
    justify-content: center;
  }

  &__open-positions-button {
    display: none;
  }

  &__apply-wrap {
    margin: 0 auto;
    margin-top: 162px;
    max-width: 900px;
    min-height: 100vh;

    @include tablet {
      margin-top: 127px;
    }

    @include mobile {
      margin-top: 84px;
    }
  }

  &__apply-breadcrumb {
    left: 0;
    margin-left: 30px;
    position: absolute;
    top: 8px;

    @include tablet {
      margin-left: 30px;
      top: 24px;
    }

    @include mobile {
      margin-left: 20px;
      top: 24px;
    }
  }

  &__apply-department-name {
    color: $color-radish;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;

    text-decoration: none;

    @include tablet {
      font-size: 14px;
    }

    &:hover {
      text-decoration: underline;
    }

    &--all {
      background: url("/assets/view-more-gray.svg") center right no-repeat;
      padding-right: 26px;

      @include tablet {
        padding-right: 22px;
      }
    }
  }
}
