.home {
  background-color: $color-black;

  &--reduce-motion {
    transition: none;

    .home__award-covers .home__award-cover-item,
    .home__background--award-common {
      transition: none;
    }

    .review,
    .home__featured-images {
      transform: scale(1);
    }

    .home__background--new-episode,
    .home__featured-text-image {
      opacity: 1;
    }

    .home__phone-view {
      transform: translate3d(0, -15%, 0) scale(0.8);      
    }

    .home__community-image--live {
      transform: translate3d(-35px, 0, 0);
    }

    .home__community-image--comment {
      transform: translate3d(35px, 0, 0);
    }
  }

  &__title {
    color: $color-white;
    font-family: "Poppins", sans-serif;
    margin-bottom: 24px;
    position: relative;
    text-align: center;
    text-shadow: 0 8px 60px #000000;
  
    @include tablet {
      text-shadow: 0 6px 48px #000000;
    }
    
    @include mobile {
      text-shadow: 0 4px 33px #000000;
    }
  
    &--no-shadow {
      text-shadow: none;
    }
  
    &--h2 {
      display: block;
      font-size: 88px;
      font-weight: 700;
      line-height: 1.14;
      text-align: left;
    
      @include tablet {
        font-size: 64.7px;
        letter-spacing: 0.5px;
        margin-bottom: 10px;
        text-align: center;
        width: 460px;
      }
    
      @include mobile {
        font-size: 44px;
        margin-bottom: 6px;
        width: 335px;
      }
    }
  
    &--sub {
      display: block;
      font-size: 40px;
      font-weight: 500;
      letter-spacing: 0.5px;
      line-height: 1.5;
      margin: 0;
      text-align: left;
    
      @include tablet {
        font-size: 23.5px;
        font-weight: 600;
        letter-spacing: 02.94px;
        text-align: center;
      }
    
      @include mobile {
        font-size: 16px;
        letter-spacing: 2px;
      }
    }
  
    &--h3 {
      font-size: 64px;
      font-weight: bold;
      line-height: 1.25;
      max-width: 750px;
    
      @include tablet {
        font-size: 51.2px;
        max-width: 580px;
      }
    
      @include mobile {
        font-size: 36px;
      }
    }
  
    &--h4 {
      font-size: 46px;
      font-weight: bold;
      text-align: center;
    
      @include tablet {
        font-size: 36.8px;
      }
      
      @include mobile {
        font-size: 25.3px;
      }
    }
  
    &--new-episode {
      margin-bottom: 93px;

  
      @include tablet {
        margin-bottom: 0;
      }
      
      @include mobile {
        margin-bottom: 0;
      }
    }
  
    &--award {
      text-align: left;
  
      @include tablet {
        text-align: center;
      }
      
      @include mobile {
        text-align: center;
      }
    }
  
    &--become-author {
      @include mobile {
        font-size: 28px;
      }
    }
  }
  
  &__section {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 1024px;
    justify-content: center;
    
    min-height: 1024px;
    overflow: hidden;
    padding: 0 40px;
    position: relative;

    @include tablet {
      height: 1194px;
      min-height: 1194px;
      padding: 0;
    }

    @include mobile {
      height: 812px;
      min-height: 812px;
    }


    &--page2 {
      @include tablet {
        margin-top: 0;
      }
    }

    &--page4 {
      display: block;
      height: 1200px;
      min-height: 1200px;
      padding-bottom: 45px;
      padding-top: 80px;
    
      @include tablet {
        height: 960px;
        min-height: 960px;
        overflow-y: hidden;
        padding: {
          bottom: 0;
          top: 36px;
        };
      }

      @include mobile {
        height: 812px;
        min-height: 812px;
        padding: {
          bottom: 0;
          top: 40px;
        };
      }
    }

    &--page5 {
      padding-bottom: 45px;
      padding-top: 0;

      @include tablet {
        padding: {
          bottom: 0;
          top: 0;
        };
      }
    }

    &--page6 {
      height: auto;
      padding-bottom: 504px;
      padding-top: 148px;

      @include tablet {
        padding: {
          bottom: 701px;
          top: 96px;
        };
      }

      @include mobile {
        padding: {
          bottom: 480px;
          top: 100px;
        };
      }
    }

    &--page7 {
      height: 400px;
      min-height: unset;

      @include tablet {
        height: 320px;
      }
    
      @include mobile {
        height: 220px;
      }
    }
  }

  &__featured-stories-wrap {
    background-position: -40px -25px;
    background-size: cover;
    height: 1020.42px;
    position: absolute;
    right: -160px;
    top: 25px;
    width: 740px;
    z-index: -1;

    @include tablet {
      display: flex;
      height: 100%;
      height: auto;
      justify-content: center;
      margin: 0 auto;
      max-width: 529px;
      position: relative;
      right: 0;
      top: 0;
      width: 100%;
    }

    @include mobile {
      max-width: 360px;
      top: -25px;
    }
  }

  &__background-image {
    min-height: 1024px;
    object-fit: cover;
    width: 100%;
  }

  &__overflow-wrap {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &__overflow-wrap-bottom-overlay {
    background: linear-gradient(180deg
    ,transparent,#000000);
    bottom: 0;
    height: 25%;
    position: absolute;
    width: 100%;

    @include tablet {
      height: 50%;
    }
  }

  &__background {
    align-items: center;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 1;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;

    &--show-child-1 {
      :nth-child(1) {
        display: block;
      }

      :not(:nth-child(1)) {
        display: none;
      }
    }

    &--show-child-2 {
      :nth-child(2) {
        display: block;
      }

      :not(:nth-child(2)) {
        display: none;
      }
    }

    &--show-child-3 {
      :nth-child(3) {
        display: block;
      }

      :not(:nth-child(3)) {
        display: none;
      }
    }

    &--show-child-4 {
      :nth-child(4) {
        display: block;
      }

      :not(:nth-child(4)) {
        display: none;
      }
    }

    &--show-child-5 {
      :nth-child(5) {
        display: block;
      }

      :not(:nth-child(5)) {
        display: none;
      }
    }

    &--featured {
      align-items: flex-start;
      transform: scale(1.1);
    }
  
    &--new-episode {
      background-image: url("/assets/covers.png");
      background-position: center center;
      background-size: contain;
      opacity: 0;
      transform: translate3d(0, 0, 0);
    }
  
    &--award-common {
      transition-duration: 0.3s;
      transition-property: fill;
      transition-timing-function: ease-out;
    }

    &--award-1 {
      fill: #56041b;
    }

    &--award-2 {
      fill: #4c2c1f;
    }

    &--award-3 {
      fill: #840000;
    }

    &--award-4 {
      fill: #560e2a;
    }

    &--award-5 {
      fill: #725c0d;
    }

    &--award-6 {
      fill: #a12940;
    }

    &--award-7 {
      fill: #002281;
    }
    
    &--reading {
      @include tablet {
        align-items: flex-end;
      }
    }    

    &--community {
      width: 100%;

      @include tablet {
        bottom: 0;
        height: 688px;
        top: unset;
      }

      @include mobile {
        height: 375px;
      }
    }

    &--become-author {
      background-image: url("/assets/home-page-6-star@2x.png");
      background-position: center 265px;
      background-size: 724px 690px;
  
      @include tablet {
        background-position-y: calc(100% - 100px);
        background-size: 579px 552px;
      }
      
      @include mobile {
        background-size: 348px 331px;
      }
    }
  }

  &__content {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1020px;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
    transform: translate3d(0, 0, 0);

    @include mobile {
      max-width: 352px;
      padding-left: 10px;
      padding-right: 10px;
    }

    &--featured {
      align-items: center;
      box-sizing: border-box;
      display: block;
      height: 1024px;
      padding-top: 172px;
      width: 100%;

      @include tablet {
        padding-top: 76px;
      }
  
      @include mobile {
        padding-top: 56px;
      }
    }

    &--new-episode {
      align-items: center;
      background-size: cover;
      display: flex;
      flex-direction: column;
      height: 800px;
      justify-content: center;
      width: 834px;

      @include tablet {
        height: 640px;
        max-width: 667px;
        width: 100%;
      }

      @include mobile {
        height: 440px;
        max-width: 375px;
        width: 100%;
      }
    }

    &--award {
      display: flex;
      flex-direction: row-reverse;
      padding-left: 0;
      padding-right: 0;
      width: 100%;
     
      @include tablet {
        flex-direction: column;
        padding-left: 40px;
        padding-right: 40px;
      } 

      @include mobile {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  &__featured-text {
    max-width: 560px;
  
    @include tablet {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      max-width: 660px;
      text-align: center;
    }
  
    @include mobile {
      max-width: 335px;
    }
  }

  &__description {
    color: $color-white;
    display: block;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;

    margin-bottom: 48px;
    text-align: center;

    width: 100%;
  
    @include tablet {
      font-size: 20px;
      margin-bottom: 0;
      max-width: 656px;
    }
  
    @include mobile {
      font-size: 14px;
    }
  
    &--featured {
      margin-bottom: 80px;
      text-align: left;
  
      @include tablet {
        margin-bottom: 9px;
        text-align: center;
      }
      
      @include mobile {
        margin-bottom: 6px;
      }
    }
  
    &--award {
      font-weight: normal;
      line-height: normal;
      margin-bottom: 60px;
      text-align: left;
  
      @include tablet {
        font-weight: 500;
        margin-bottom: 48px;
        text-align: center;
      }
      
      @include mobile {
        margin-bottom: 32px;
      }
    }
  
    &--become-author {
      margin-bottom: 96px;
      text-align: center;
  
      @include tablet {
        margin-bottom: 48px;
        max-width: 656px;
      }
  
      @include mobile {
        margin-bottom: 32px;
      }
    }
  
    &--question {
      margin-bottom: 54px;
      text-align: center;
  
      @include tablet {
        margin-bottom: 36px;
      }
      
      @include mobile {
        margin-bottom: 28px;
      }
    }
  }

  &__featured-images {
    position: absolute;
    transform: scale(0.97);
    width: 100%;

    @include mobile {
      width: calc(100% + 4em);
    }

    &--show-child-1 {
      .home__featured-image-wrap:nth-child(1) {
        display: block;
      }

      .home__featured-image-wrap:not(:nth-child(1)) {
        display: none;
      }
    }

    &--show-child-2 {
      .home__featured-image-wrap:nth-child(2) {
        display: block;
      }

      .home__featured-image-wrap:not(:nth-child(2)) {
        display: none;
      }
    }

    &--show-child-3 {
      .home__featured-image-wrap:nth-child(3) {
        display: block;
      }

      .home__featured-image-wrap:not(:nth-child(3)) {
        display: none;
      }
    }

    &--show-child-4 {
      .home__featured-image-wrap:nth-child(4) {
        display: block;
      }

      .home__featured-image-wrap:not(:nth-child(4)) {
        display: none;
      }
    }

    &--show-child-5 {
      .home__featured-image-wrap:nth-child(5) {
        display: block;
      }

      .home__featured-image-wrap:not(:nth-child(5)) {
        display: none;
      }
    }
  }

  &__featured-image-wrap {
    @include tablet {
      width: 100%;
    }
  }

  &__mobile-frame {
    display: block;
    margin: 0 auto;
    position: relative;
    width: 652px;

    @include tablet {
      height: 100%;
      width: 100%;
    }
  }

  &__featured-cover-image {
    position: relative;
    top: -25px;
    width: 740px;

    @include tablet {
      width: 100%;
    }
  }

  &__featured-text-image {
    bottom: 338px;
    left: 210px;
    opacity: 0;
    position: absolute;
    width: 320px;
  
    @include tablet {
      bottom: 175px;
      left: 136px;
      width: 259px;
    }
  
    @include mobile {
      bottom: 36%;
      left: 28%;
      width: 45%;
    }
  }

  &__mobile-start-reading {
    bottom: 246px;
    left: 250px;
    position: absolute;
    width: 240px;
  
    @include tablet {
      bottom: 198px;
      left: 168px;
      width: 194px;
    }
  
    @include mobile {  
      bottom: 24.3%;
      left: 31.8%;
      width: 37%;
    }
  }

  &__reviews {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 900px;
    position: relative;
    width: 100%;
  
    @include tablet {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 100px;
      padding: 0;
    }
  
    @include mobile {
      margin-top: 81px;
      max-width: 330px;
    }
  }

  &__new-episode {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__award-article {
    display: flex;
    flex: 1;
    flex-direction: column;
    
    @include tablet {
      align-items: center;
    }
    
    @include mobile {
      align-items: center;
    }
  }

  &__authors {
    margin-bottom: 62px;
    
    @include tablet {
      margin-bottom: 49px;
    }
  
    @include mobile {
      margin-bottom: 32px;
    }
  }

  &__authors-control {
    align-items: center;
    display: flex;
  }

  &__authors-control-button {
    background: none;
    border: 0 none;
    cursor: pointer;
  }

  &__authors-control-line {
    border-top: 1px solid #ffffff;
    height: 1px;
    margin: 0 20px;
    width: 100px;
  }

  &__authors-control-number {
    color: $color-white;
    font-family: Poppins;
    font-size: 20px;
    text-align: center;
    width: 28px;

    &--current {
      font-weight: bold;
    }
  }

  &__award-covers {
    align-items: center;
    display: flex;
    height: 635px;
    justify-content: center;
    margin-right: 84px;
    position: relative;
    width: 376px;
  
    @include tablet {
      height: 508px;
      margin-right: 0;
      margin-top: 64px;
      width: 301px;
    }
    
    @include mobile {
      height: 345px;
      margin-right: 0;
      margin-top: 34px;
      width: 205px;
    }
  }

  &__award-cover-item {
    backface-visibility: hidden;
    display: none;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateZ(0);
    transition-duration: 0.3s;
    transition-property: transform, opacity;
    transition-timing-function: ease-out;

    &--depth0 {
      display: block;
      z-index: 3;
    }

    &--depth1 {
      display: block;
      transform: rotate(5deg) scale(0.8) translateX(16%);
      z-index: 2;
    }

    &--depth2 {
      display: block;
      transform: rotate(-5deg) scale(0.8) translateX(-12%);
      z-index: 1;
    }

    &--depth3 {
      display: block;
      transform: rotate(5deg) scale(0.8) translateX(-12%);
      z-index: 0;
    }

    &--out {
      display: block;
      opacity: 0;
      transform: translateX(-180px);
    }
    
  }

  &__award-cover-image {
    background-color: $color-gray-4c;
    border-radius: 8px;
    box-shadow: 0 16px 60px 0 #00000033;
    height: 635px;
    width: 376px;
  
    @include tablet {
      height: 508px;
      width: 301px;
    }
    
    @include mobile {
      border-radius: 5px;
      box-shadow: 0 11px 41px 0 #00000033;
      height: 345px;
      width: 205px;
    }
  }

  &__reading-wrap {
    padding: 0;
  }

  &__reading-text {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;

    @include tablet {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__reading-bg-wrap {
    height: 812px;
    position: relative;
    width: 1020px;

    @include tablet {
      bottom: 0;
      height: 100%;
    }
  }
  
  &__reading-bg-l {
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
  }

  &__reading-bg-r {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
  }

  &__reading-bg-cover {
    position: absolute;
    width: 240px;

    @include tablet {
      width: 220px;
    }
    
    @include mobile {
      width: 149px;
    }

    &--1 {
      left: 0;
      top: 100px;

      @include tablet {
        left: 15%;
        top: 80px;
      }

      @include mobile {
        left: 0%;
        top: 130px;
        width: 157px;
      }
    }

    &--2 {
      left: 290px;
      top: 280px;

      @include tablet {
        left: 20px;
        top: 400px;
      }

      @include mobile {
        left: -5%;
        top: 365px;
        width: 157px;
      }
    }

    &--3 {
      bottom: 0;
      left: 0;

      @include tablet {
        left: 15%;
        top: 720px;
        width: 157px;
      }

      @include mobile {
        left: -2%;
        top: 600px;
      }
    }

    &--4 {
      right: 290px;
      top: 0;

      @include tablet {
        right: 30px;
        top: 0;
      }

      @include mobile {
        right: -2%;
        top: 0;
      }
    }

    &--5 {
      bottom: 100px;
      right: 290px;

      @include tablet {
        right: 10%;
        top: 320px;
      }

      @include mobile {
        right: 0;
        top: 220px;
      }
    }

    &--6 {
      right: 0;
      top: 160px;

      @include tablet {
        right: 20px;
        top: 640px;
      }

      @include mobile {
        right: -5%;
        top: 440px;
      }
    }

    &--7 {
      bottom: -60px;
      right: 0;

      @include tablet {
        display: none;
      }
    }
  }

  &__phone-view {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 800px;
    position: relative;
    width: 800px;
    
    @include tablet {
      height: unset;
      width: 100%;
    }

    @include mobile {
      width: 100%;
    }
  }

  &__phone-view-frame {
    width: 100%;
    z-index: 1;
  }

  &__phone-view-video {
    border-radius: 40px;

    left: 200px;
    position: absolute;

    top: 148px;
    width: 399px;
    z-index: 0;

    @include tablet {
      border-radius: 20px;
      box-sizing: border-box;
      left: 0;
      padding: 18.6% 25.1%;
      top: 0;
      width: 100%;
    }
    
    @include mobile {
      border-radius: 5px;
    }
  }

  &__reading-image--pc-only {
    @include tablet {
      display: none;
    }
  }

  &__reading-image--mobile-only {
    display: none;
  
    @include tablet {
      display: block;
    }
  }

  &__community-dots {
    max-width: 1167px;
    position: absolute;
    top: 151px;
    width: 100%;
  }

  &__community-radar {
    position: absolute;
    width: 860px;
    
    @include tablet {
      width: 686px;
    }

    @include mobile {
      width: 402px;
    }
  }

  &__community-oval {
    background-image: conic-gradient(from 0.59turn, #004aff00, #004aff 0.41turn, #2c95f000 0.55turn, #004aff00);
    border-radius: 800px;
    height: 800px;
    position: absolute;
    width: 800px;

    @include tablet {
      border-radius: 640px;
      height: 640px;
      width: 640px;
    }

    @include mobile {
      border-radius: 375px;
      height: 375px;
      width: 375px;
    }
  }

  &__community-outline-text {
    color: #ffffff00;
    display: block;
    font-family: Poppins;
    font-size: 60px;
    font-weight: bold;
    position: absolute;
    text-align: center;
    text-shadow: 0 8px 60px #000000;
    -webkit-text-stroke: 0.6px #ffffff;

    @include tablet {
      font-size: 48px;
      text-shadow: 0 6px 48px #000000;
      -webkit-text-stroke: 0.7px #ffffff;
    
    }
    
    @include mobile {
      font-size: 33px;
      text-shadow: 0 4px 33px #000000;
      -webkit-text-stroke: 0.5px #ffffff;
    }

    &--writer {
      left: 45px;
      top: 191px;

      @include tablet {
        left: 0;
        text-align: center;
        top: 0;
        width: 100%;
      }
      
      @include mobile {
        top: 15px;
      }
    }

    &--reader {
      right: 0;
      top: 410px;

      @include tablet {
        left: 0;
        text-align: center;
        top: 650px;
        width: 100%;
      }
      
      @include mobile {
        top: 330px;
      }
    }
  }

  &__community-text {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 1;
  }

  &__community-image-wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 85px;
    width: 100%;

    @include tablet {
      margin-bottom: 105px;
      margin-top: 195px;
    }

    @include mobile {
      margin-bottom: 45px;
      margin-top: 125px;
    }
  }

  &__community-image {
    width: 269px;

    @include tablet {
      height: 431.7px;
      width: 210px;
    } 

    @include mobile {
      height: 267px;
      width: 130px;
    } 

    &--live {
      transform: translate3d(134.5px, 0, 0);

      @include tablet {
        transform: translate3d(105px, 0, 0);
      }

      @include mobile {
        transform: translate3d(65px, 0, 0);
      }
    }

    &--comment {
      transform: translate3d(-134.5px, 0, 0);

      @include tablet {
        transform: translate3d(-105px, 0, 0);
      }

      @include mobile {
        transform: translate3d(-65px, 0, 0);
      }
    }
  }

  &__become-author-link {
    align-items: center;
    background-color: $color-white;
    border-radius: 40px;
    color: $color-black;
    display: flex;
    font-family: Poppins, sans-serif;
    font-size: 24px;
    font-weight: bold;
    height: 80px;

    justify-content: center;
    padding: 0 40px;
    text-decoration: none;
  
    @include tablet {
      border-radius: 32px;
      font-size: 19.2px;
      height: 64px;
      padding: 0 28px;
    }
    
    @include mobile {
      border-radius: 22px;
      font-size: 13.2px;
      height: 44px;
      padding: 0 15px;
    }
  }

  &__question {
    align-items: center;
    background-image: linear-gradient(to bottom, #ff008a 20%, #99ccff);
    bottom: 0;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1020px;
    object-fit: contain;
    padding: 56px 0 68px;
    position: relative;
    width: 100%;
  
    @include tablet {
      padding: 46px 0 58px;
    }
    
    @include mobile {
      padding: 36px 0 48px;
    }
  }

  .footer {
    @include tablet {
      padding-bottom: 68px;
    }
  }
}

// Big store link
.big-store {
  display: flex;
  position: relative;

  @include tablet {
    display: none;
  }

  &__link {
    margin-left: -12px;
    width: 235px;
    /* original image contains some margin */
  
    @include tablet {
      display: none;
    }
  }

  &__icon {
    width: 100%;
  }  
}

// storebar on mobile
.storebar {
  display: none;

  @include tablet {
    align-items: center;
    background-color: #222222fa;
    border-radius: 23.2px;
    bottom: 8px;
    box-shadow: 0 0 29px 0 #000000;
    box-sizing: border-box;
    display: flex;
    height: 87px;
    margin: 0 20px 8px;
    position: fixed;
    transform: translate3d(0, 0, 0);
    width: calc(100% - 40px);
    z-index: 2;
    padding: {
      left: 12px;
      right: 12px;
    }
  }

  @include mobile {
    border-radius: 16px;
    box-shadow: 0 0 20px 0 #000000;
    height: 60px;
    margin: 0 8px 8px;
    width: calc(100% - 16px);
  }

  &__icon {
    @include tablet {
      align-items: center;
      background-color: $color-radish;
      border-radius: 14.5px;
      display: flex;
      height: 64px;
      justify-content: center;
      width: 64px;
    }
  
    @include mobile {
      border-radius: 10px;
      height: 44px;
      width: 44px;
    }
  }

  &__icon-image {
    @include tablet {
      height: 36px;
      width: 36px;
    }
  
    @include mobile {
      height: 24px;
      width: 24px;
    }
  }

  &__text {
    @include tablet {
      display: flex;
      flex: 1;
      flex-direction: column;
      line-height: 1.34;
      margin-left: 17px;
    }
  
    @include mobile {
      margin-left: 12px;
    }
  }

  &__title {
    @include tablet {
      color: $color-white;
      font-family: Poppins;
      font-size: 21.8px;
      font-weight: 600;
    }
  
    @include mobile {
      font-size: 15px;
    }
  }

  &__desc {
    @include tablet {
      color: $color-gray-6;
      font-family: Poppins;
      font-size: 17.4px;
    }
  
    @include mobile {
      font-size: 12px;
    }
  }

  &__link {
    @include tablet {
      align-items: center;
      background-color: #ff008a;
      border-radius: 21.8px;
      color: $color-white;
      display: flex;
      font-family: Poppins;
      font-size: 18.9px;
      font-weight: bold;
      height: 44px;
      justify-content: center;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      width: 122px; 
    }
  
    @include mobile { 
      font-size: 13px;
      height: 30px;
      width: 84px; 
    }
  }
}

// Home Review item
.review {
  align-items: center;
  display: flex;
  flex-direction: column;

  transform: scale(0);
  width: 30%;

  @include tablet {
    margin-bottom: 61px;
    width: 370px;
  }

  @include mobile {
    margin-bottom: 20px;
    width: 165px;
  }

  &__stars {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 152px;
  
    @include tablet {
      width: 121px;
    }
  
    @include mobile {
      width: 86px;
    }
  }

  &__star {
    height: 24px;
    width: 24px;
  
    @include tablet {
      height: 19px;
      width: 19px;
    }
  
    @include mobile {
      height: 13px;
      width: 13px;
    }
  }

  &__text {
    color: $color-white;
    display: block;
    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.4;
    text-align: center;
    width: 300px;
  
    @include tablet {
      color: $color-white;
      display: block;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.4;
      text-align: center;
      width: 375px;
  
      margin: {
        bottom: 10px;
        top: 11px;
      }
    }
  
    @include mobile {
      font-size: 14px;
      width: 165px;
  
      margin: {
        bottom: 6px;
        top: 6px;
      }
    }
  
    margin: {
      bottom: 12px;
      top: 16px;
    }
  }

  &__desc {
    color: $color-gray-4c;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  
    @include tablet {
      font-size: 17.5px;
    }
  
    @include mobile {
      font-size: 12px;
    }
  }
}

// Author item
.author-item {
  align-items: center;
  display: none;
  position: relative;

  &__photo {
    background-color: $color-gray-4c;
    border-radius: 74px;
    height: 74px;
    margin-right: 20px;
    overflow: hidden;
    width: 74px;
  
    @include tablet {
      border-radius: 59px;
      height: 59px;
      margin-right: 16px;
      width: 59px;
    }
    
    @include mobile {
      border-radius: 40px;
      height: 40px;
      margin-right: 10px;
      width: 40px;
    }
  }

  &__name {
    color: $color-white;
    display: block;
    font-family: Poppins;
    font-size: 28px;
    font-weight: 600;

    @include tablet {
      font-size: 22.4px;
    }
    
    @include mobile {
      font-size: 15.2px;
    }
  }

  &__desc {
    color: $color-white;
    display: block;
    font-family: Poppins;
    font-size: 21px;
  
    @include tablet {
      font-size: 16.8px;
    }
    
    @include mobile {
      font-size: 11.4px;
    }
  }

  &--show {
    display: flex;
  }
}

